<template>
  <div>
    <div class="invoice-listing-main-wrapper">
      <div
        class="invoice-listing-table-with-select-wrapper"
        :style="[rightSideBarVisibility ? ' width : 73%' : ' width : 100%']"
      >
        <div class="invoice-listing-table-wrapper">
          <div class="invoice-listing-legends-section">
            <div style="margin-left: auto">
              <span class="dot" style="background: #ff4e17"></span>
              <span class="text" style="color: #ff4e17">Pending Invoice</span>
              <span class="dot" style="background: #f4db8b"></span>
              <span class="text" style="color: #f4db8b"
                >Sample Invoice Attached</span
              >
              <span class="dot" style="background: #ffc488"></span>
              <span class="text" style="color: #ffc488">Sent to Client</span>
              <span class="dot" style="background: #28643a"></span>
              <span class="text" style="color: #28643a"
                >Sample Invoice Approved</span
              >
              <span class="dot" style="background: #8a737b"></span>
              <span class="text" style="color: #8a737b"
                >Final Invoice Attached</span
              >
              <span class="dot" style="background: #6fcf97"></span>
              <span class="text" style="color: #6fcf97">Payment Received</span>
            </div>
            <div>
              <a-select
                ref="select"
                style="width: 11.25rem; margin-left: 1rem"
                class="vacancy-profile-add-invoice-select"
                placeholder="Actions"
                :disabled="selKeys.length < 1"
              >
                <a-select-option value="export">Export</a-select-option>
                <a-select-option value="delete">Delete</a-select-option>
                <a-select-option v-if="selKeys.length == 1" value="lock"
                  >Lock</a-select-option
                >
                <a-select-option v-if="selKeys.length == 1" value="unlock"
                  >Unlock</a-select-option
                >
                <a-select-option
                  value="sampleInvoice"
                  @click="openAttachSampleInvoice"
                  >Attach Sample Invoice</a-select-option
                >
                <a-select-option
                  value="finalInvoice"
                  @click="openAttachFinalInvoice"
                  >Attach Final Invoice</a-select-option
                >
                <a-select-option value="payment" @click="openPaymentReceived"
                  >Payment Received</a-select-option
                >
              </a-select>
            </div>
            <div>
              <FilterOutlined
                style="font-size: 1.5rem; margin: 0 0.5rem"
                @click="showBillingFilters"
              />
            </div>
            <div
              style="
                padding: 0.15rem 0.35rem;
                background: #f0f0f0;
                border-radius: 5px;
              "
            >
              <MenuOutlined @click="showColumnManager" />
            </div>
          </div>
          <a-spin
            :spinning="loading"
            :style="[
              rightSideBarVisibility
                ? ' width: 65%; padding-top: 80px; z-index: 9; position: fixed'
                : 'width: 90%; padding-top: 80px; z-index: 9; position: fixed',
            ]"
            :indicator="indicator"
          />
          <div class="invoice-listing-table">
            <a-table
              :columns="invoiceColumnData"
              :data-source="invoiceData"
              :row-selection="invoiceListingRowSelection"
              :pagination="false"
              :rowKey="(record) => record.invoiceDate.id"
            >
              <template #invoiceDate="{ text }">
                <div
                  class="invoice-date"
                  style="cursor: pointer"
                  @click="invoiceProfile(text.id)"
                >
                  {{ text.date }}
                </div>
              </template>

              <template #client="{ text }">
                <div style="display: flex">
                  <div v-if="text.logo" style="margin: 5px 5px 0px 0px">
                    <img src="{{text.logo}}" alt="Client Logo" />
                  </div>
                  <div v-else style="margin: 5px 5px 0px 0px">
                    <a-avatar>
                      <template #icon><UserOutlined /></template>
                    </a-avatar>
                  </div>
                  <div>
                    <div class="invoice-title-in-table">
                      {{ text.name }}
                    </div>
                    <u class="invoice-id-in-table">{{ text.companyId }}</u>
                  </div>
                </div>
              </template>

              <template #vacancy="{ text }">
                <div
                  class="invoice-title-in-table"
                  @click="vacancyProfile(text.id)"
                >
                  {{ text.vacancyName }}
                </div>
                <u class="invoice-id-in-table">{{ text.vacancyId }}</u>
                <BarChartOutlined
                  class="invoice-taxonomy-in-table"
                  @click="showVacancyTaxonomy(text.id)"
                />
              </template>

              <template #camdidate="{ text }">
                <div style="display: flex">
                  <div v-if="text.imageUrl" style="margin: 5px 5px 0px 0px">
                    <img v-if="text" src="{{text}}" alt="Candidate image" />
                  </div>
                  <div v-else style="margin: 5px 5px 0px 0px">
                    <a-avatar>
                      <template #icon><UserOutlined /></template>
                    </a-avatar>
                    &nbsp;
                  </div>
                  <div style="padding-top: 5px">
                    <span class="invoice-title-in-table">
                      {{ text.name }}
                    </span>
                    <div>
                      <span>
                        <a class="invoice-id-in-table">
                          {{ text.resumeId }}
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </template>

              <template #billDate="{ text }">
                <div class="invoice-date">{{ text }}</div>
              </template>
              <template #amount="{ text }">
                <div class="invoice-date">Rs. {{ text }}</div>
              </template>
              <template #joiningDate="{ text }">
                <div class="invoice-date">{{ text }}</div>
              </template>
              <template #status="{ text }">
                <div v-if="text === 'Pending Invoice'">
                  <span class="dot" style="background: #ff4e17"></span>
                </div>
                <div v-if="text === 'Sample Invoice Attached'">
                  <span class="dot" style="background: #f4db8b"></span>
                </div>
                <div v-if="text === 'Sent to Client'">
                  <span class="dot" style="background: #ffc488"></span>
                </div>
                <div v-if="text === 'Sample Invoice Approved'">
                  <span class="dot" style="background: #28643a"></span>
                </div>
                <div v-if="text === 'Final Invoice Attached'">
                  <span class="dot" style="background: #8a737b"></span>
                </div>
                <div v-if="text === 'Payment Received'">
                  <span class="dot" style="background: #6fcf97"></span>
                </div>
              </template>
            </a-table>
            <div class="invoice-listing-view-more-wrapper">
              <div v-if="showViewMore">
                <div>
                  <span
                    class="invoice-list-view-more-text"
                    @click="fetchMoreInvoices()"
                    ><PlusCircleOutlined /> View more</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Taxonomy Widget -->
      <div>
        <a-modal
          class="filter-modal"
          title=""
          width="500px"
          v-model:visible="vacancyModalVisibility"
          :closable="false"
          centered
          footer=""
          destroyOnClose
        >
          <VacancyWidget :id="vacancyTaxonomyId" />
        </a-modal>
      </div>

      <!-- Manage Column Widget -->
      <div>
        <a-modal
          class="column-modal"
          title=""
          v-model:visible="manageColumnsModalVisibility"
          :closable="false"
          centered
          width="400px"
        >
          <div>
            <div style="width=80%">
              <manageColumnComponent :masterList="manageColumnData" />
            </div>
          </div>
          <template #footer>
            <a-button> </a-button>
          </template>
        </a-modal>
      </div>

      <!-- Filter Modal -->
      <div>
        <a-modal
          footer=""
          class="filter-modal"
          title=""
          v-model:visible="invoiceFilterVisibility"
          :closable="false"
          centered
          width="800px"
        >
          <div>
            <div style="width=80%">
              <Filter
                @closeFilter="billingFilterModalCancel"
                @filterModalOk="billingFilterModalOk"
              />
            </div>
          </div>
        </a-modal>
      </div>

      <div>
        <a-modal
          class="filter-modal"
          title=""
          width="500px"
          v-model:visible="attachSampleInvoice"
          :closable="false"
          centered
          footer=""
          destroyOnClose
          style="padding: 10px"
        >
          <AttachSampleInvoice @onClickCancel="onClickCancel" />
        </a-modal>
      </div>
      <div>
        <a-drawer
          v-model:visible="showInvoiceProfile"
          :placement="right"
          :closable="false"
          width="500"
          destroyOnClose
          ><InvoiceProfile
            @onCLickCrossIcon="onCLickCrossIcon"
            :invoiceId="invoiceId"
        /></a-drawer>
      </div>
      <div>
        <a-modal
          class="filter-modal"
          title=""
          width="500px"
          v-model:visible="attachFinalInvoice"
          :closable="false"
          centered
          footer=""
          destroyOnClose
          style="padding: 10px"
        >
          <AttachFinalInvoice @onClickCancelFinal="onClickCancelFinal" />
        </a-modal>
      </div>
      <div>
        <a-modal
          class="filter-modal"
          title=""
          width="500px"
          v-model:visible="paymentReceived"
          :closable="false"
          centered
          footer=""
          destroyOnClose
          style="padding: 10px"
        >
          <PaymentReceived
            @onClickCancelPayment="onClickCancelPayment"
            :invoiceAmount="invoiceAmount"
            :invoiceId="invoiceId"
          />
        </a-modal>
      </div>
      <!-- Right Side Bar -->
      <div>
        <LeftCircleTwoTone
          v-if="!rightSideBarVisibility"
          @click="showRightSideBar"
          style="color: blue; font-size: 1.5rem"
        />
        <RightCircleTwoTone
          v-if="rightSideBarVisibility"
          @click="showRightSideBar"
          style="
            font-size: 1.5rem;
            position: fixed;
            z-index: 9;
            transform: translateX(-10px);
          "
        />
      </div>
      <div
        class="action-bar"
        style="transition: all 1s ease"
        v-if="rightSideBarVisibility"
      >
        <RightSideBar />
      </div>
    </div>
  </div>
</template>
<script>
import Services from "@/services/apis";
import {
  FilterOutlined,
  PlusCircleOutlined,
  UserOutlined,
  BarChartOutlined,
  MenuOutlined,
  LeftCircleTwoTone,
  RightCircleTwoTone,
} from "@ant-design/icons-vue";

import { ref, reactive, toRefs, onMounted, h } from "vue";
import VacancyWidget from "../../vacancy/components/widget.vue";
import { useRouter } from "vue-router";
import Utils from "../../../utils";
import RightSideBar from "./rightSideBar.vue";
import manageColumnComponent from "../../common/components/manageColumns.vue";
import Filter from "./billingFilter.vue";
import AttachSampleInvoice from "./AttachSampleInvoice.vue";
import AttachFinalInvoice from "./AttachFinalInvoice.vue";
import PaymentReceived from "./PaymentReceived.vue";
import { useStore } from "vuex";
import InvoiceProfile from "./InvoiceProfile.vue";
import { LoadingOutlined } from "@ant-design/icons-vue";

false;

export default {
  components: {
    RightSideBar,
    FilterOutlined,
    MenuOutlined,
    PlusCircleOutlined,
    RightCircleTwoTone,
    BarChartOutlined,
    LeftCircleTwoTone,
    UserOutlined,
    VacancyWidget,
    manageColumnComponent,
    Filter,
    AttachSampleInvoice,
    AttachFinalInvoice,
    InvoiceProfile,
    PaymentReceived,
  },
  setup() {
    let rightSideBarVisibility = ref(false);
    const store = useStore();
    onMounted(() => {
      fetchInvoiceListing();
    });
    const router = useRouter();
    const vacancyProfile = (id) => {
      router.push(`/vacancies/${id}`);
    };
    let selKeys = ref([]);
    let showViewMore = ref(false);
    let invoiceData = reactive([]);
    let invoiceDataCount = ref();
    let vacancyTaxonomyId = ref("");
    const vacancyModalVisibility = ref(false);
    let pageCount = 0;
    let filterDataObject;
    const showVacancyTaxonomy = (vacancyId) => {
      vacancyTaxonomyId.value = vacancyId;
      vacancyModalVisibility.value = true;
    };

    let manageColumnsModalVisibility = ref(false);
    const columnManagerModalOk = (e) => {
      manageColumnsModalVisibility.value = false;
    };

    let manageColumnData = reactive([
      {
        id: "vacancy_details",
        constants: "VACANCY_DETAILS",
        isVisible: true,
        name: "Vacancy Details",
        children: [
          {
            name: "Vacancy ID",
            id: "vacancy",
            isSelecetd: true,
          },
          {
            name: "Company Name",
            id: "vacancy",
            isSelecetd: true,
          },
          {
            name: "Vac. Title",
            id: "vacancy",
            isSelecetd: true,
          },
          {
            name: "Company ID",
            id: "companyId",
            isSelecetd: true,
          },
          {
            name: "Entity Name",
            id: "entityName",
            isSelecetd: false,
          },
        ],
      },
      {
        id: "candidate_details",
        constants: "CANDIDATE_DETAILS",
        isVisible: true,
        name: "Candidate Details",
        children: [
          {
            name: "Joining Date",
            id: "joiningDate",
            isSelecetd: true,
          },
          {
            name: "Salary",
            id: "salary",
            isSelecetd: false,
          },
          {
            name: "Name",
            id: "name",
            isSelecetd: true,
          },
          {
            name: "ResumeID",
            id: "resumeId",
            isSelecetd: true,
          },
        ],
      },
      {
        id: "invoice_details",
        constants: "INVOICE_DETAILS",
        isVisible: true,
        name: "Invoice Details",
        children: [
          {
            name: "Invoice Date",
            id: "invoiceDate",
            isSelecetd: true,
          },
          {
            name: "Amount",
            id: "amount",
            isSelecetd: true,
          },
          {
            name: "Status",
            id: "status",
            isSelecetd: true,
          },
          {
            name: "Bill Date",
            id: "billDate",
            isSelecetd: true,
          },
          {
            name: "Short Payment",
            id: "shortPayment",
            isSelecetd: false,
          },
          {
            name: "Terms",
            id: "terms",
            isSelecetd: false,
          },
        ],
      },
      {
        id: "other",
        constants: "OTHER",
        isVisible: true,
        name: "Other",
        children: [
          {
            name: "Lead CIC",
            id: "leadCIC",
            isSelecetd: false,
          },
          {
            name: "Note",
            id: "note",
            isSelecetd: false,
          },
        ],
      },
    ]);

    const showColumnManager = () => {
      manageColumnsModalVisibility.value = true;
    };

    const showRightSideBar = () => {
      rightSideBarVisibility.value = !rightSideBarVisibility.value;
    };

    let invoiceColumnData = reactive([
      {
        title: "Invoice Date",
        dataIndex: "invoiceDate",
        key: "invoiceDate",
        slots: { customRender: "invoiceDate" },
        align: "center",
        width: 100,
      },
      {
        title: "Client",
        dataIndex: "client",
        key: "client",
        slots: { customRender: "client" },
        width: 300,
      },
      {
        title: "Vacancy",
        dataIndex: "vacancy",
        key: "vacancy",
        slots: { customRender: "vacancy" },
        width: 180,
      },
      {
        title: "Candidate",
        dataIndex: "candidate",
        key: "candidate",
        slots: { customRender: "candidate" },
        width: 150,
      },
      {
        title: "Bill Date",
        dataIndex: "billDate",
        key: "billDate",
        slots: { customRender: "billDate" },
        align: "center",
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        slots: { customRender: "amount" },
        align: "center",
      },
      {
        title: "Joining Date",
        dataIndex: "joiningDate",
        key: "joiningDate",
        slots: { customRender: "joiningDate" },
        align: "center",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        slots: { customRender: "status" },
        align: "center",
      },
    ]);

    const invoiceFilterVisibility = ref(false);
    const attachSampleInvoice = ref(false);
    const attachFinalInvoice = ref(false);
    const paymentReceived = ref(false);

    const openAttachSampleInvoice = () => {
      attachSampleInvoice.value = true;
    };
    const openAttachFinalInvoice = () => {
      attachFinalInvoice.value = true;
    };
    const openPaymentReceived = () => {
      paymentReceived.value = true;
    };
    const showBillingFilters = () => {
      invoiceFilterVisibility.value = true;
    };
    const billingFilterModalOk = (payload, value) => {
      pageCount = 0;
      fetchInvoiceListing(payload);
      invoiceFilterVisibility.value = value;
      filterDataObject = Utils.deepCopyObject(payload);
    };

    const billingFilterModalCancel = (value) => {
      invoiceFilterVisibility.value = value;
    };
    let invoiceAmount = ref("0");

    const invoiceListingRowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        console.log("selectedRowKeys", selectedRowKeys);
        selKeys.value = selectedRowKeys;
        invoiceAmount.value = selectedRows[0].amount;
        invoiceId.value = selectedRowKeys[0];
      },
    };

    const fetchMoreInvoices = () => {
      pageCount++;
      if (filterDataObject && Object.keys(filterDataObject).length > 0) {
        fetchInvoiceListing(filterDataObject);
      } else {
        fetchInvoiceListing();
      }
    };
    const onClickCancel = (value) => {
      attachSampleInvoice.value = value;
    };
    const showInvoiceProfile = ref(false);
    const onClickCancelFinal = (value) => {
      attachFinalInvoice.value = value;
    };
    const onClickCancelPayment = (value) => {
      paymentReceived.value = value;
    };

    const onCLickCrossIcon = () => {
      showInvoiceProfile.value = false;
    };
    const invoiceProfile = (invoicevalue) => {
      console.log("id", invoicevalue);
      invoiceId.value = invoicevalue;
      showInvoiceProfile.value = true;
    };

    let loading = ref(true);
    const indicator = h(LoadingOutlined, {
      style: {
        fontSize: "50px",
        margin: "0 auto",
      },
      spin: ref(loading),
    });
    let invoiceId = ref("");
    const fetchInvoiceListing = async (value) => {
      let listData = [];
      let payload = {
        q: "",
        mode: "LISTING",
        status: ["ACTIVE", "INACTIVE"],
        pageNo: pageCount,
      };
      if (pageCount > 0) {
        listData = invoiceData;
      } else {
        invoiceData.splice(0);
      }
      if (typeof value === "object") {
        payload = {
          ...value,
          pageNo: pageCount,
          mode: "LISTING",
        };
        console.log("filterDataObject", payload);
      }
      loading.value = true;

      await Services.getInvoiceListing(payload)
        .then((response) => {
          const { data } = response;
          data &&
            data.count &&
            data.data.map((x) => {
              listData.push({
                invoiceDate: {
                  date: x.invoiceDate
                    ? Utils.displayShortDate(x.invoiceDate)
                    : "-",
                  id: x.id,
                },
                client: {
                  logo: x.account.logo,
                  name: x.account.name ? x.account.name : "-",
                  companyId: x.account.accountId ? x.account.accountId : "-",
                },
                vacancy: {
                  id: x.vacancy.id,
                  vacancyName: x.vacancy.title ? x.vacancy.title : "-",
                  vacancyId: x.vacancy.vacancyId ? x.vacancy.vacancyId : "-",
                },
                candidate: x.candidate
                  ? {
                      name: x.candidate.name ? x.candidate.name : "-",
                      resumeId: x.candidate.resumeId
                        ? x.candidate.resumeId
                        : "-",
                    }
                  : "-",
                billDate: x.billDate ? Utils.displayShortDate(x.billDate) : "-",
                amount: x.revenue ? Utils.rounding(x.revenue) : "-",
                joiningDate: x.joiningDate
                  ? Utils.displayShortDate(x.joiningDate)
                  : "-",
                status: x.invoiceStatus ? x.invoiceStatus : "-",
              });
            });
          invoiceDataCount.value = data.count;
        })
        .catch((e) => {});
      loading.value = false;

      Object.assign(invoiceData, listData);
      showViewMore.value = invoiceDataCount.value > invoiceData.length;
    };

    return {
      rightSideBarVisibility,
      invoiceColumnData,
      invoiceData,
      invoiceListingRowSelection,
      fetchMoreInvoices,
      vacancyModalVisibility,
      selKeys,
      // widget_id,
      showVacancyTaxonomy,
      vacancyProfile,
      manageColumnsModalVisibility,
      columnManagerModalOk,
      manageColumnData,
      showColumnManager,
      invoiceFilterVisibility,
      showBillingFilters,
      billingFilterModalOk,
      billingFilterModalCancel,
      pageCount,
      showViewMore,
      invoiceDataCount,
      vacancyTaxonomyId,
      showRightSideBar,
      openAttachSampleInvoice,
      attachSampleInvoice,
      onClickCancel,
      attachFinalInvoice,
      openAttachFinalInvoice,
      onClickCancelFinal,
      showInvoiceProfile,
      onCLickCrossIcon,
      invoiceProfile,
      invoiceId,
      loading,
      indicator,
      openPaymentReceived,
      paymentReceived,
      onClickCancelPayment,
      invoiceAmount,
    };
  },
};
</script>
<style lang="scss">
@import "../styles/billingDashboard.scss";
</style>
