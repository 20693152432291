<template>
  <div class="main-container">
    <div class="invoice-filter-header-text">
      <span class="title">Filter by</span>
      <span @click="resetFields" class="reset">Reset Changes</span>
    </div>
    <div class="filter-container">
      <a-form>
        <div class="invoice-details">
          <div class="invoice-details-form">
            <div class="form-sub-section">
              <div class="filter-form-elements" style="flex-basis: 34%">
                <a-form-item>
                  <span class="input-label">Vacancy ID</span>
                  <a-input
                    v-model:value="modelRef.vacancyId"
                    class="form-input"
                    placeholder="Enter"
                  >
                  </a-input>
                </a-form-item>
              </div>
              <div
                class="filter-form-elements"
                style="flex-basis: 34%; padding: 0px 15px"
              >
                <a-form-item>
                  <span class="input-label">Resume ID</span>
                  <a-input
                    v-model:value="modelRef.resumeId"
                    class="form-input"
                    placeholder="Enter"
                  >
                  </a-input>
                </a-form-item>
              </div>
              <div class="filter-form-elements" style="flex-basis: 34%">
                <a-form-item>
                  <span class="input-label">Client</span>
                  <a-select
                    allowClear
                    show-search
                    placeholder="Select"
                    class="invoice-filter-select"
                    :options="company_name_options"
                    @search="getCompanyName"
                    v-model:value="modelRef.company"
                    :default-active-first-option="false"
                    :filter-option="false"
                  >
                  </a-select>
                </a-form-item>
              </div>
            </div>
            <div class="form-sub-section">
              <div class="filter-form-elements" style="flex-basis: 34%">
                <a-form-item>
                  <span class="input-label">Lead CIC</span>
                  <a-select
                    show-search
                    class="invoice-filter-select"
                    style="width: 100%"
                    mode="tags"
                    v-model:value="modelRef.leadCic"
                    :filter-option="false"
                    :options="lead_cic_options"
                    @search="fetchLeadCIC"
                    placeholder="Select"
                  >
                  </a-select>
                </a-form-item>
              </div>
              <!-- <div style="display: flex; flex-basis: 57%"> -->
              <div
                class="filter-form-elements"
                style="flex-basis: 34%; padding: 0px 15px"
              >
                <a-form-item>
                  <span class="input-label">Invoice Status</span>
                  <a-select
                    class="invoice-filter-select"
                    v-model:value="modelRef.invoiceStatus"
                    :filter-option="false"
                    :options="invoice_status_options"
                    @search="fetchInvoiceStatusData"
                    placeholder="Select"
                  ></a-select>
                </a-form-item>
                <!-- </div> -->
              </div>
              <div style="flex-basis: 34%"></div>
            </div>
            <div class="form-sub-section" style="align-items: center">
              <div class="filter-form-elements" style="flex-basis: 34%">
                <a-form-item>
                  <span class="input-label">Entity Name</span>
                  <a-input
                    v-model:value="modelRef.entityName"
                    class="form-input"
                    placeholder="Enter"
                  >
                  </a-input>
                </a-form-item>
              </div>
              <div
                class="filter-form-elements"
                style="flex-basis: 34%; padding: 0px 15px"
              >
                <a-form-item>
                  <span class="input-label">Candidate Name</span>
                  <a-input
                    v-model:value="modelRef.candidateName"
                    class="form-input"
                    placeholder="Enter"
                  >
                  </a-input>
                </a-form-item>
              </div>
              <div class="filter-form-elements" style="flex-basis: 34%">
                <div style="display: flex">
                  <div style="margin-left: auto; margin-right: 20px">
                    <a-button
                      type="link"
                      style="
                        color: rgb(76, 81, 85);
                        font-size: $font_size_12;
                        font-family: Poppins;
                        font-weight: 500;
                        border-radius: 2px;
                        border: 1px solid #d9d9d9;
                      "
                      key="back"
                      @click="closeFilter"
                      >Cancel</a-button
                    >
                  </div>
                  <div>
                    <a-button
                      key="submit"
                      style="
                        background: RGB(228, 129, 106);
                        color: #ffffff;
                        border-radius: 2px;
                        font-size: $font_size_12;
                        font-family: Poppins;
                        font-weight: 500;
                      "
                      @click="filterModalOk"
                      >Apply</a-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </a-form>
    </div>
  </div>
</template>
<script>
import { Form } from "ant-design-vue";
import { defineComponent, ref, onMounted, reactive } from "vue";
import Services from "../../../services/apis";
import Utils from "../../../utils";

const useForm = Form.useForm;

export default defineComponent({
  setup(props, context) {
    let company_name_options = ref([]);
    let invoice_status_options = ref([]);
    let lead_cic_options = ref([]);

    let modelRef = reactive({
      vacancyId: "",
      resumeId: "",
      company: ref([]),
      leadCic: ref([]),
      invoiceStatus: ref([]),
      entityName: "",
      candidateName: "",
    });
    let payload = {
      vacancyId: "",
      resumeId: "",
      company: ref([]),
      leadCic: ref([]),
      invoiceStatus: ref([]),
      entityName: "",
      candidateName: "",
    };
    const { resetFields } = useForm(modelRef);

    const fetchInvoiceStatusData = (value) => {
      let payload = {};
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE", types: ["INVOICE"] };
      Services.getVacancyStatusDetail(payload)
        .then((response) => {
          const { data } = response;
          invoice_status_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const getCompanyName = (value) => {
      let payload = {};
      payload = { q: value, mode: "AUTOCOMPLETE" };
      Services.getAccountListing(payload)
        .then((response) => {
          const { data } = response;
          let tempData = [];
          data.map((x) => {
            tempData.push({
              value: x.account.id,
              label: x.account.name,
            });
          });
          Object.assign(company_name_options.value, tempData);
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchLeadCIC = (value) => {
      Services.userListing({ q: value, pageNo: 0, mode: "AUTOCOMPLETE" })
        .then((response) => {
          const { data } = response;
          lead_cic_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    onMounted(() => {
      fetchInvoiceStatusData();
      getCompanyName();
      fetchLeadCIC();
    });

    function closeFilter() {
      context.emit("closeFilter", false);
    }

    function filterModalOk() {
      console.log("payload :>> ", modelRef);
      let tempObject = Utils.deepCopyObject(payload);

      modelRef.vacancyId
        ? (tempObject.vacancyId = modelRef.vacancyId)
        : delete tempObject.vacancyId;
      modelRef.resumeId
        ? (tempObject.resumeId = modelRef.resumeId)
        : delete tempObject.resumeId;
      modelRef.company && modelRef.company.length > 0
        ? (tempObject.company = modelRef.company)
        : delete tempObject.company;
      modelRef.leadCic.length > 0
        ? (tempObject.leadCic = modelRef.leadCic)
        : delete tempObject.leadCic;
      modelRef.invoiceStatus.length > 0
        ? (tempObject.invoiceStatus = modelRef.invoiceStatus)
        : delete tempObject.invoiceStatus;
      modelRef.entityName
        ? (tempObject.entityName = modelRef.entityName)
        : delete tempObject.entityName;
      modelRef.candidateName
        ? (tempObject.candidateName = modelRef.candidateName)
        : delete tempObject.candidateName;

      context.emit("filterModalOk", tempObject, false);
      // resetFilter();
    }

    function resetFilter() {
      modelRef.vacancyId = "";
      modelRef.resumeId = "";
      modelRef.company = ref([]);
      modelRef.leadCic = ref([]);
      modelRef.invoiceStatus = ref([]);
      modelRef.entityName = "";
      modelRef.candidateName = "";
    }

    return {
      modelRef,
      resetFields,
      closeFilter,
      filterModalOk,
      resetFilter,

      // All select items options
      invoice_status_options,
      lead_cic_options,
      company_name_options,

      // Select Api call Funtions
      fetchInvoiceStatusData,
      getCompanyName,
      fetchLeadCIC,
    };
  },
});
</script>

<style lang="scss">
@import "../styles/billingFilter.scss";
</style>
