<template>
  <div class="attach-sample-invoice-text">Attach Sample Invoice</div>
  <div class="upload-with-notes-wrapper">
    <div>
      <a-upload
        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
        v-model:file-list="fileList"
      >
        <a-button>
          <upload-outlined></upload-outlined>
          Upload
        </a-button>
      </a-upload>
    </div>
    <div class="attach-sample-notes-wrapper">
      Notes<span style="color: red">*</span>
      <a-input placeholder="Enter" type="text" style="margin-top: 20px" />
    </div>
    <div class="cancel-save-btn-wrapper">
      <div class="attach-sample-invoice-cancel-text" @click="onClickCancel">
        Cancel
      </div>
      <div><a-button type="primary" @click="onClickSave">Save</a-button></div>
    </div>
  </div>
</template>

<script>
import { UploadOutlined } from "@ant-design/icons-vue";

export default {
  components: {
    UploadOutlined,
  },
  setup(props, context) {
    const onClickCancel = () => {
      context.emit("onClickCancel", false);
    };
    const onClickSave = () => {
      onClickCancel();
    };
    return {
      onClickCancel,
      onClickSave,
    };
  },
};
</script>

<style lang="scss">
@import "../styles/AttachSampleInvoice.scss";
</style>