<template>
  <div class="invoice-profile-main-wrapper">
    <div style="display: flex; padding: 20px; align-items: center">
      <div class="invoice-profile-invoice-text">Invoice</div>
      <div style="margin-left: auto; margin-right: 10px">
        <!-- <LockOutlined style="font-size: 20px" /><UnlockOutlined
          style="font-size: 20px"
        /> -->
      </div>
      <div>
        <a-select
          ref="select"
          style="width: 170px; margin-right: 15px"
          class="vacancy-profile-add-invoice-select"
          @focus="focus"
          placeholder="Actions"
        >
          <a-select-option value="lock">Lock</a-select-option>

          <a-select-option value="unlock">Unlock</a-select-option>
          <a-select-option value="sampleInvoice"
            >Attach Sample Invoice</a-select-option
          >
          <a-select-option value="finalInvoice"
            >Attach Final Invoice</a-select-option
          >
          <a-select-option value="payment">Payment Received</a-select-option>
        </a-select>
      </div>
      <div>
        <CloseOutlined style="font-size: 20px" @click="onCLickCrossIcon" />
      </div>
    </div>
    <div style="display: flex; border-top: 0.5px solid #f0f0f0">
      <div
        style="width: 33.33%; padding: 20px; border-right: 0.5px solid #f0f0f0;text-align: center"
      >
        <a-avatar :size="64">
          <template #icon><UserOutlined /></template>
        </a-avatar>
      </div>
      <div style="width: 66.67%; padding: 20px">
        <div>
          Company name :
          {{
            invoiceProfileData.account.name
              ? invoiceProfileData.account.name
              : "-"
          }}
        </div>
        <div>
          Company Id :
          {{
            invoiceProfileData.account.accountId
              ? invoiceProfileData.account.accountId
              : "-"
          }}
        </div>
        <div>
          Vacancy Title :
          {{
            invoiceProfileData.vacancy.title
              ? invoiceProfileData.vacancy.title
              : "-"
          }}
        </div>
        <div>
          Vacancy Id :
          {{
            invoiceProfileData.vacancy.vacancyId
              ? invoiceProfileData.vacancy.vacancyId
              : "-"
          }}
        </div>
      </div>
    </div>
    <div
      style="display: flex; background: #f8f8f8; border: 0.5px solid #f0f0f0"
    >
      <div
        style="
          width: 33.33%;
          padding: 8px 15px;
          border-right: 0.5px solid #f0f0f0;
        "
      >
        Candidate Name
      </div>
      <div
        style="
          width: 33.33%;
          padding: 8px 15px;
          border-right: 0.5px solid #f0f0f0;
        "
      >
        Resume ID
      </div>
      <div style="width: 33.33%; padding: 8px 15px">Salary</div>
    </div>
    <div style="display: flex">
      <div
        style="
          width: 33.33%;
          padding: 8px 15px;
          border-right: 0.5px solid #f0f0f0;
        "
      >
        {{
          invoiceProfileData.candidate.name
            ? invoiceProfileData.candidate.name
            : "-"
        }}
      </div>
      <div
        style="
          width: 33.33%;
          padding: 8px 15px;
          border-right: 0.5px solid #f0f0f0;
        "
      >
        {{
          invoiceProfileData.candidate.resumeId
            ? invoiceProfileData.candidate.resumeId
            : "-"
        }}
      </div>
      <div style="width: 33.33%; padding: 8px 15px">
        {{
          invoiceProfileData.candidate.salary
            ? invoiceProfileData.candidate.salary
            : "-"
        }}
      </div>
    </div>
    <div
      style="display: flex; background: #f8f8f8; border: 0.5px solid #f0f0f0"
    >
      <div
        style="
          width: 33.33%;
          padding: 8px 15px;
          border-right: 0.5px solid #f0f0f0;
        "
      >
        Invoice Date
      </div>
      <div style="width: 66.67%; padding: 8px 15px">Vacancy Type</div>
    </div>
    <div style="display: flex">
      <div
        style="
          width: 33.33%;
          padding: 8px 15px;
          border-right: 0.5px solid #f0f0f0;
        "
      >
        {{
          invoiceProfileData.invoice.date
            ? invoiceProfileData.invoice.date
            : "-"
        }}
      </div>
      <div style="width: 66.67%; padding: 8px 15px">
        {{
          invoiceProfileData.vacancy.vacancyType
            ? invoiceProfileData.vacancy.vacancyType
            : "-"
        }}
      </div>
    </div>
    <div style="background: #f8f8f8; border: 0.5px solid #f0f0f0">
      <div style="padding: 8px 15px">Billing Status</div>
    </div>
    <div>
      <div style="padding: 8px 15px">Pending</div>
    </div>
    <div
      style="display: flex; background: #f8f8f8; border: 0.5px solid #f0f0f0"
    >
      <div
        style="
          width: 33.33%;
          padding: 8px 15px;
          border-right: 0.5px solid #f0f0f0;
        "
      >
        Terms
      </div>
      <div
        style="
          width: 33.33%;
          padding: 8px 15px;
          border-right: 0.5px solid #f0f0f0;
        "
      >
        Billing Date
      </div>
      <div style="width: 33.33%; padding: 8px 15px">Joining Date</div>
    </div>
    <div style="display: flex">
      <div
        style="
          width: 33.33%;
          padding: 8px 15px;
          border-right: 0.5px solid #f0f0f0;
        "
      >
        All(15%)
      </div>
      <div
        style="
          width: 33.33%;
          padding: 8px 15px;
          border-right: 0.5px solid #f0f0f0;
        "
      >
        {{
          invoiceProfileData.candidate.billingDate
            ? invoiceProfileData.candidate.billingDate
            : "-"
        }}
      </div>
      <div style="width: 33.33%; padding: 8px 15px">
        {{
          invoiceProfileData.candidate.joiningDate
            ? invoiceProfileData.candidate.joiningDate
            : "-"
        }}
      </div>
    </div>
    <div
      style="
        background: #f8f8f8;
        padding: 8px 15px;
        border: 0.5px solid #f0f0f0;
      "
    >
      Entity Name:
      {{
        invoiceProfileData.candidate.entityName
          ? invoiceProfileData.candidate.entityName
          : "-"
      }}
    </div>
    <div style="padding: 8px 15px">
      <div>
        Invoice Amount :
        {{
          invoiceProfileData.invoice.amount
            ? invoiceProfileData.invoice.amount
            : "0"
        }}
      </div>
      <div>
        Gross Amount :
        {{
          invoiceProfileData.invoice.gross
            ? invoiceProfileData.invoice.gross
            : "0"
        }}
      </div>
      <div>
        Received Amount :
        {{
          invoiceProfileData.invoice.received
            ? invoiceProfileData.invoice.received
            : "0"
        }}
      </div>
      <div>
        Short Payment :
        {{
          invoiceProfileData.invoice.short
            ? invoiceProfileData.invoice.short
            : "0"
        }}
      </div>
    </div>
    <div
      style="
        background: #f8f8f8;
        padding: 8px 15px;
        border: 0.5px solid #f0f0f0;
      "
    >
      PA : NBFC
    </div>
    <div style="background: #f8f8f8; padding: 8px 15px">SPA : Credit Funds</div>
    <div
      style="
        background: #f8f8f8;
        padding: 8px 15px;
        border: 0.5px solid #f0f0f0;
      "
    >
      Invoice No :
      {{
        invoiceProfileData.invoice.invoiceNumber
          ? invoiceProfileData.invoice.invoiceNumber
          : "-"
      }}
    </div>
    <div style="padding: 8px 15px; border-bottom: 0.5px solid #f0f0f0">
      <div>
        Lead CIC :
        <span v-for="(user, id) in invoiceProfileData.vacancy.cic.user" :key="id"
          >{{ user.name ? user.name : "-" }}&nbsp;
        </span>
      </div>
      <div>
        Team :
        <span
          v-for="(team, id) in invoiceProfileData.vacancy.cic.team"
          :key="id"
          >{{ team.name ? team.name : "-" }}</span
        >
      </div>
      <div>
        Sub Team :<span
          v-for="(subTeam, id) in invoiceProfileData.vacancy.cic.subTeam"
          :key="id"
          >{{ subTeam.name ? subTeam.name : "-" }}</span
        >
      </div>
      <div v-for="(note, id) in invoiceProfileData.notes" :key="id">
        Notes : {{ note.title ? note.title : "-" }}
      </div>
    </div>
    <div style="text-align: end; margin: 10px 10px">
      <a-button @click="onCLickCrossIcon">Close</a-button>
    </div>
  </div>
</template>
<script>
import {} from "vue";
import {
  CloseOutlined,
  LockOutlined,
  UserOutlined,
  UnlockOutlined,
} from "@ant-design/icons-vue";
import { onMounted, reactive } from "vue";
import Services from "@/services/apis";
import Utils from "../../../utils";
export default {
  components: {
    CloseOutlined,
    UserOutlined,
    // LockOutlined,
    // UnlockOutlined,
  },
  props: ["invoiceId"],

  setup(props, context) {
    onMounted(() => {
      fetchInvoice(props.invoiceId);
    });
    const onCLickCrossIcon = () => {
      context.emit("onCLickCrossIcon", false);
    };

    let invoiceProfileData = reactive({
      invoice: {
        id: "",
        invoiceNumber: "",
        date: "",
        amount: 0,
        gross: 0,
        short: 0,
        received: 0,
      },
      vacancy: {
        cic: [
          {
            team: {
              id: "",
              name: "",
            },
            subTeam: {
              id: "",
              name: "",
            },
            user: [
              {
                id: "",
                name: "",
              },
            ],
          },
        ],
        id: "",
        vacancyId: "",
        vacancyType: "",
        name: "",
      },
      account: {
        id: "",
        accountId: "",
        name: "",
      },
      candidate: {
        id: "",
        candidateId: "",
        name: "",
        logo: "",
        linkedInUrl: "",
        salary: 0,
        terms: {
          name: "",
          percentage: 0,
          isFixed: true,
          fee: 0,
          limit: 0,
        },
        joiningDate: "",
        entityName: "",
        billingDate: "",
      },

      uploads: [
        {
          id: "",
          name: "",
          description: "",
          type: "",
          subType: "",
          url: "",
          size: 0,
        },
      ],
      notes: [
        {
          id: "",
          title: "",
          description: "",
        },
      ],
    });
    const fetchInvoice = (id) => {
      console.log(id);
      Services.getInvoice(id)
        .then((response) => {
          const { data } = response;

          Object.assign(invoiceProfileData, data);
          console.log(invoiceProfileData,'===============')
          invoiceProfileData.invoice.date = Utils.displayInvoiceDate(
            invoiceProfileData.invoice.date
          );
        })
        .catch((e) => {});
    };

    return { onCLickCrossIcon, fetchInvoice, props, invoiceProfileData };
  },
};
</script>

<style lang="scss" >
@import "../styles/InvoiceProfile.scss";
</style>