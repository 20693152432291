<template>
  <div class="attach-sample-invoice-text">Receive Payment</div>
  <div class="add-payment-wrapper">
    <div>
      <div class="receive-payment-input-box">Invoice Amount</div>
      <a-form-item>
        <a-input
          placeholder="Enter"
          v-model:value="modelRef.invoiceAmount"
          class="receive-payment-input-fields"
          disabled
        />
      </a-form-item>
    </div>

    <div style="display: flex; margin-top: 15px">
      <div style="width: 100%; margin-right: 7px">
        <span class="receive-payment-input-box">GST</span>
        <div style="width: 100%; margin-top: 5px">
          <a-form-item>
            <a-select
              ref="select"
              v-model:value="modelRef.gst.amount"
              @focus="focus"
              class="payment-received-select-field"
              @change="onChangeGst"
            >
              <a-select-option label="18%" value="18">18%</a-select-option>
              <a-select-option label="0%" value="0">0%</a-select-option>
            </a-select>
          </a-form-item>
        </div>
      </div>
      <div style="width: 100%">
        <span class="receive-payment-input-box">TDS(%)</span>
        <div style="width: 100%; margin-top: 5px">
          <a-form-item>
            <a-select
              ref="select"
              v-model:value="modelRef.tds.amount"
              @focus="focus"
              class="payment-received-select-field"
              @change="onChangeTds"
            >
              <a-select-option value="invoice"
                >10% Invoice Amount</a-select-option
              >
              <a-select-option value="gross">10% Gross Amount</a-select-option>
              <a-select-option value="0">0% Invoice Amount</a-select-option>
            </a-select>
          </a-form-item>
        </div>
      </div>
    </div>
    <div
      style="display: flex; justify-content: space-between; margin-top: 15px"
    >
      <div>
        <div class="receive-payment-input-box">Gross Amount</div>
        <a-form-item>
          <a-input
            v-model:value="modelRef.grossAmount"
            placeholder="Enter"
            class="receive-payment-input-fields"
          />
        </a-form-item>
      </div>
      <div>
        <div class="receive-payment-input-box">Received Amount</div>
        <a-form-item>
          <a-input
            v-model:value="modelRef.receivedAmount"
            placeholder="Enter"
            class="receive-payment-input-fields"
            @change="onChangeReceivedAmount"
          />
        </a-form-item>
      </div>
    </div>

    <div class="receive-payment-input-box" style="margin-top: 15px">
      Short Amount : {{ modelRef.shortPayment }}
    </div>
    <div class="cancel-save-btn-wrapper">
      <div
        class="attach-sample-invoice-cancel-text"
        @click="onClickCancelPayment"
      >
        Cancel
      </div>
      <div><a-button type="primary" @click="onClickSave">Save</a-button></div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, reactive } from "vue";
import { Form } from "ant-design-vue";
import Services from "@/services/apis";
import { message } from "ant-design-vue";
import { useRouter } from "vue-router";

export default {
  components: {},
  props: ["invoiceAmount", "invoiceId"],
  setup(props, context) {
    const router = useRouter();
    let tempInvoice = ref("");

    onMounted(() => {
      tempInvoice.value = props.invoiceAmount;
      modelRef.invoiceAmount = tempInvoice.value;
      modelRef.grossAmount = tempInvoice.value;
    });

    const useForm = Form.useForm;
    const { resetFields, validate, validateInfos } = useForm(modelRef);

    let modelRef = reactive({
      paymentId: "",
      invoiceId: "",
      invoiceAmount: "",
      gst: {
        applied: false,
        amount: 0,
      },
      tds: {
        applied: false,
        appliedOn: "",
        amount: 0,
      },
      grossAmount: "",
      receivedAmount: 0,
      shortPayment: "",
    });

    const onClickCancelPayment = () => {
      context.emit("onClickCancelPayment", false);
    };
    const onChangeGst = (value) => {
      if (value === "18") {
        modelRef.grossAmount =
          parseInt(modelRef.invoiceAmount) +
          parseInt((modelRef.invoiceAmount * 18) / 100);
      } else {
        modelRef.grossAmount = modelRef.invoiceAmount;
      }
      modelRef.shortPayment =
        parseInt(modelRef.grossAmount) - parseInt(modelRef.receivedAmount);
    };
    const onChangeTds = (value) => {
      if (value === "invoice") {
        modelRef.grossAmount =
          parseInt(modelRef.invoiceAmount) +
          parseInt((modelRef.invoiceAmount * 10) / 100);
      } else {
        modelRef.grossAmount = modelRef.invoiceAmount;
      }
      modelRef.shortPayment =
        parseInt(modelRef.grossAmount) - parseInt(modelRef.receivedAmount);
    };

    const onChangeReceivedAmount = () => {
      if (modelRef.receivedAmount === "") {
        modelRef.shortPayment = modelRef.grossAmount;
      }
      modelRef.shortPayment =
        modelRef.grossAmount - parseInt(modelRef.receivedAmount);
    };
    const onClickSave = () => {
      if (modelRef.gst.amount === "18") {
        modelRef.gst.applied = true;
        modelRef.gst.amount = 18;
      }
      if (modelRef.tds.amount === "invoice") {
        modelRef.tds.applied = true;
        modelRef.tds.appliedOn = "INVOICE";
        modelRef.tds.amount = 10;
      } else if (modelRef.tds.amount === "gross") {
        modelRef.tds.applied = true;
        modelRef.tds.appliedOn = "GROSS";
        modelRef.tds.amount = 10;
      }
      modelRef.invoiceAmount = parseInt(modelRef.invoiceAmount);
      modelRef.receivedAmount = parseInt(modelRef.receivedAmount);
      modelRef.grossAmount = parseInt(modelRef.grossAmount);
      modelRef.invoiceId = props.invoiceId;


      Services.receivePayments({ data: modelRef })
        .then((response) => {
          message.success(`Payment Received Successfully`);
          //   router.push("/billing");
          onClickCancelPayment();
        })
        .catch((e) => {});
    };
    return {
      onClickCancelPayment,
      onClickSave,
      tempInvoice,
      modelRef,
      onChangeGst,
      onChangeTds,
      onChangeReceivedAmount,
    };
  },
};
</script>

<style lang="scss">
@import "../styles/AttachSampleInvoice.scss";
</style>